import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import useApiRequest from './ApiComponent';
import '../assets/css/ContactForm.css';

const ContactForm = () => {
    const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Contactus');
    const { response: response2, error: error2 } = useApiRequest('GET', '/api/get_Socialmedia');
    // console.log("response1 ",response1)
    const Dataview = response1?.['Contact List'];
    const DataviewLink = response2?.['Socialmedia List'];
    // console.log("DataviewLink ",DataviewLink)

    const socialIcons = {
        Facebook: 'fab fa-facebook',
        Instagram: 'fab fa-instagram',
        LinkedIn: 'fab fa-linkedin',
        Twitter: 'fab fa-twitter'
    };

    // Function to get the correct icon class based on the linkget value
    const getIconClass = (linkget) => {
        switch(linkget.toLowerCase()) {
            case 'facebook':
                return socialIcons.Facebook;
            case 'instagram':
                return socialIcons.Instagram;
            case 'linkedin':
            case 'linkdin':
                return socialIcons.LinkedIn;
            case 'twitter':
                return socialIcons.Twitter;
            default:
                return 'fas fa-link'; // Default icon if none match
        }
    }
    if (!Dataview) {
        return <div></div>;
      }

    return (
        <div className="container mt-2">
            <div className="row">
                <div className="col-md-5">
                    <div className="contact-info">
                        <h4 style={{ backgroundColor: '#f1f1f1' }}>CONTACT INFORMATION</h4>
                        <p><i className="fas fa-phone-alt icon-first"></i> {Dataview[0]?.number}</p>
                        <p><i className="fas fa-envelope icon"></i> {Dataview[0]?.email}</p>
                        <p><i className="fas fa-map-marker-alt icon"></i> {Dataview[0]?.location}</p>
                        <div style={{marginTop:'100px'}}>
                            <div className="social-icons" style={{backgroundColor:'#f1f1f1'}}>
                                {DataviewLink && DataviewLink.map((link, index) => (
                                    <a key={index} href={link.link} target="_blank" rel="noopener noreferrer">
                                        <i className={getIconClass(link.linkget)}></i>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="form-container">
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" placeholder="Name" />
                        </div>
                        <div className="form-group">
                            <input type="email" className="form-control" id="email" placeholder="Email" />
                        </div>
                        <div className="form-group">
                            <input type="tel" className="form-control" id="phone" placeholder="Phone Number" />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="message" placeholder="Message" />
                        </div>
                        <div className='btn-container'>
                            <input type="button" className='submit-button' value='Send Message' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
