import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css//Subscribe.css'; 

const Subscribe = () => {
  return (
    <section className="subscribe-section" style={{padding:'30px',backgroundColor:'#EEEEEE'}}>
      <div className="container" style={{backgroundColor:"#EEEEEE"}}>
        <div className='subscribe-container' >
        <h2 className='subscribe-head'>Subscribe</h2>
        <p className='subscribe-p'>Subscribe to stay tuned for new news and latest updates. Let's do it!</p>
        <form className="form-inline justify-content-center" style={{backgroundColor:'#EEEEEE'}}>
          <input
            type="email"
            className="input-subscribe"
            placeholder="Enter your email address"
          />
          <button type="submit" className="subscribe-btn">
            Subscribe
          </button>
        </form>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
