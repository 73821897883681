import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import 'bootstrap/dist/css/bootstrap.min.css';
import useApiRequest from './ApiComponent';
import { useNavigate } from 'react-router-dom';
import '../assets/css/MoreArticles.css';

const MoreArticles = () => {
  const { response: response1, error: error1 } = useApiRequest('GET', '/api/get_Magazinarticles');
  const navigate = useNavigate();
  const url = localStorage.getItem('url');
  const Dataview = response1?.['magazinarticles List'];

  // Log the data to check the response
  console.log("API Response:", response1); // Log the full API response
  console.log("Dataview:", Dataview); // Log the Dataview (magazinarticles List)

  const [visibleCount, setVisibleCount] = useState(8);

  const loadMore = () => {
    setVisibleCount(prevCount => prevCount + 8);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  const handledetialsPage = (article) => {
    navigate('/ArticleDetails', { state: { article } });
  };

  if (!Dataview) {
    return <div>Loading.</div>; // Show a message if there's no data
  }

  const filteredArticles = Dataview.filter(
    article => article.status == "Article" && article.active == 1 && article.tranding == null && article.breaking == null
  );

  const visibleArticles = filteredArticles.slice(0, visibleCount);
  console.log("visibleArticles ",filteredArticles)

  return (
    <>
      <div className='articles-container'>
        <div className="container">
          <div className="more-articles">
            <h2>MORE ARTICLES</h2>
          </div>
          <div className="row">
            {visibleArticles.length > 0 ? (
              visibleArticles.map((article, index) => (
                <div className="col-md-3" key={index} onClick={() => { handledetialsPage(article) }} style={{cursor: 'pointer'}}>
                  <div className="card article-card">
                    <div className="article-image">
                      <img src={`${url}/public/image/${article.image}`} alt="Article Image" />
                    </div>
                    <div className="card-body article-content">
                      <h5 className="card-title-Art">{article.heading}</h5>
                      <p className="card-text-Art">{truncateText(DOMPurify.sanitize(article.shortdes), 20)}</p>
                      <div className="d-flex justify-content-between date-Art">
                        <div className="article-date" style={{ backgroundColor: '#fff' }}>{article.date}</div>
                        <div className="article-eye" style={{ backgroundColor: '#fff' }}><i className="fa fa-eye"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No articles found.</div> // Show a message if no articles match the filter
            )}
          </div>

          {visibleCount < filteredArticles.length && (
            <div className="text-center" style={{paddingBottom:"25px"}}>
              <button className="More-Button" onClick={loadMore}> Read More...</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MoreArticles;
