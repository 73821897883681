import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import useApiRequest from './ApiComponent'; // Import your custom hook for API requests
import Footer from './Footer';
import SubscribeVart from './SubscribeVart';
import Addvertisement from './Advertisement';

const ArticlePage = ({ details }) => {
    const { response, error } = useApiRequest('GET', '/api/get_Tranding');
    const url = localStorage.getItem('url');
    const navigate = useNavigate();
    
    const [searchQuery, setSearchQuery] = useState('');
    const [showFullContent, setShowFullContent] = useState(false); // State for "Show More"
    
    const trendingData = response?.['Tranding List'];
    const handleReadViewDetails = (article) => {
        navigate('/ArticleDetails', { state: { article } });
    };
    
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const sortedTrendingData = [...(trendingData || [])].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const latestTrendingData = sortedTrendingData.slice(0, 10);

    const filteredTrendingData = latestTrendingData.filter(item =>
        item.detail?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.author?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.write?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const splitIntoChunks = (text, chunkSize) => {
        if (!text) return [];
        const words = text.split(' ');
        const chunks = [];
        for (let i = 0; i < words.length; i += chunkSize) {
            chunks.push(words.slice(i, i + chunkSize).join(' '));
        }
        return chunks;
    };

    const getYouTubeIDs = (urls) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^"&?\/\s]{11})/;
        return urls.split(',').map(url => {
            const match = url.trim().match(regex);
            return match ? match[1] : '';
        }).filter(id => id !== '');
    };

    const shareUrl = window.location.href; // Current page URL to share
    const shareText = details.heading; // Text to share
    const category = details.category || '';
    const heading = details.heading || '';
    const shortDec = details.shortdes||';'
    const bodyChunks = splitIntoChunks(details.body, 50); // Split the article into chunks of 50 words
    const image = `${url}/public/image/${details.image}` || ''; 
    const videoURLs = details.videofile || ''; 
    const videoIDs = getYouTubeIDs(videoURLs);
    const authorImage = details.authorImage || ''; 
    const authorName = details.author || ''; 

    const contentSections = [];
    const videoInsertionPoints = [2, 5]; 

    // Add initial content chunks
    for (let i = 0; i < bodyChunks.length; i++) {
        if (i === 0 && image) {
            contentSections.push(
                <div>
                    {shortDec}
                </div>
            );
            contentSections.push(
                <div>
                    <div className="d-flex mt-3">
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                                        <div className='shareiconface'></div>
                                    </a>
                                    <a href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`} target="_blank" rel="noopener noreferrer">
                                        <div className='shareiconx'></div>
                                    </a>
                                    <a href={`https://www.instagram.com/?url=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                                        <div className='shareiconinsta'></div>
                                    </a>
                                    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`} target="_blank" rel="noopener noreferrer">
                                        <div className='shareiconlinkedin'></div>
                                    </a>
                                    <a href={`https://api.whatsapp.com/send?text=${shareText}%20${shareUrl}`} target="_blank" rel="noopener noreferrer">
                                        <div className='shareiconwhatsapp'></div>
                                    </a>
                                </div>
                </div>
            );
            contentSections.push(
                <div key="image" style={{ marginTop: '20px' }}>
                    <img src={image} alt="Article Image" style={{ width: '100%', height: 'auto' }} />
                </div>
            );
        }
        contentSections.push(<div key={`content-${i}`} dangerouslySetInnerHTML={{ __html: bodyChunks[i] }} />);

        if (videoInsertionPoints.includes(i)) {
            const videoID = videoIDs.shift(); 
            if (videoID) {
                contentSections.push(
                    <div key={`video-${i}`} style={{ marginTop: '20px' }}>
                        <iframe
                            width="100%"
                            height="315"
                            src={`https://www.youtube.com/embed/${videoID}`}
                            title={`YouTube video ${i}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                );
            }
        }
    }

    videoIDs.forEach((videoID, index) => {
        contentSections.push(
            <div key={`remaining-video-${index}`} style={{ marginTop: '20px' }}>
                <iframe
                    width="100%"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoID}`}
                    title={`YouTube video ${index}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        );
    });

    const wordLimit = 500; // Set your word limit here (e.g., 500-600)
    const limitedContent = contentSections.slice(0, wordLimit / 50); // Display only 500 words initially

    return (
        <>
            <div className="container article-container">
                <Addvertisement />
                <div className="row">
                    <div className="col-md-8 mt-2">
                        <div className="article-content mt-4">
                            <div className="article-body">
                                <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{category}</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }} className='detailshead'>{heading}</div>
                                </div>
                                {authorImage && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <img src={`${url}/public/image/${authorImage}`} alt="Author" style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }} />
                                        <div>{authorName}</div>
                                    </div>
                                )}
                                {/* Conditionally render limited content or full content */}
                                {showFullContent ? contentSections : limitedContent}

                                {!showFullContent && contentSections.length > limitedContent.length && (
                                     <div className="text-center" style={{paddingBottom:"25px"}}>
                                     <button className="More-Button"onClick={() => setShowFullContent(true)}> Read More...</button>
                                   </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Sidebar */}
                    <div className="col-md-4 mt-3">
                        <div className="sidebar ">
                            <div className='searchBox'>
                                <h5 className='search-text'>Search</h5>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        aria-label="Search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <div className='Trending-container'>
                                <h4 className='Trending-text'>Trending</h4>
                                <hr />
                                <div className="trending-items-container">
                                    {filteredTrendingData.map((item, index) => (
                                        <div key={item.id} style={{ backgroundColor: '#EEEEEE' }} onClick={() => handleReadViewDetails(item)}>
                                            <div className="trending-item">
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ backgroundColor: '#EEEEEE' }}>
                                                        <img
                                                            src={`${url}/public/image/${item.image}`}
                                                            alt="Trending Image"
                                                            className='search-box-img'
                                                        />
                                                    </div>
                                                    <div style={{
                                                        lineHeight: '17px',
                                                        fontSize: '13px',
                                                        fontWeight: '500',
                                                        padding: '10px',
                                                        backgroundColor: '#EEEEEE'
                                                    }}>
                                                        <div style={{ backgroundColor: '#EEEEEE' }}>
                                                            <span style={{ backgroundColor: '#EEEEEE' }}>{item.detail || item.description}</span>
                                                        </div>
                                                        <div style={{
                                                            marginTop: '13%',
                                                            backgroundColor: '#EEEEEE'
                                                        }}>
                                                            <span style={{
                                                                fontSize: '10px',
                                                                color: '#676767', backgroundColor: '#EEEEEE'
                                                            }}>{item.write}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <SubscribeVart/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArticlePage;
