import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css//Subscribe.css'; 

const SubscribeVart = () => {
  return (
    <section className="subscribe-section-Vart sticky-subscribe">
      <div className="p-3" style={{backgroundColor:'#EEEEEE'}}>
        <div className='' style={{backgroundColor:'#EEEEEE'}}>
        <h2 className='subscribe-head'>Subscribe</h2>
        <p className='subscribe-p'>Subscribe to stay tuned for new news and latest updates. Let's do it!</p>
        <form className="form-inline-Vart justify-content-center" style={{backgroundColor:'#EEEEEE'}}>
          <input
            type="email"
            className="input-subscribe"
            placeholder="Enter your email address"
            style={{width:'100%'}}
          /><br/>
          <button type="submit" className="subscribe-btn" style={{marginTop:'30px'}}>
            Subscribe
          </button>
        </form>
        </div>
      </div>
    </section>
  );
};

export default SubscribeVart;
